.faq-popover {
  .MuiPopover-paper {
    bottom: 20px;
    right: 20px;
  }
}
.shop-page-dialog {
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 1280px !important;
  }

  .MuiBackdrop-root {
    background: none !important;
  }
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  background: url(../images/texture.jpg);
  padding: 20px;
  text-align: center;

  .close {
    position: absolute;
    bottom: 22px;
    right: 16px;
    button {
      padding: 0;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    .sunny-logo,
    .jane-logo {
      margin: 0 auto 25px;
      display: block;
    }
    .sunny-logo {
      width: 150px;
    }
    .jane-logo {
      width: 86px;
    }
    .jane-gold-logo {
      width: 130px;
      margin: 0 auto 15px;
      display: block;
    }
    .shop-sunny {
      border-bottom: 1px solid black;
      padding-bottom: 35px;
      margin-bottom: 32px;
    }

    a {
      text-decoration: none;
      button {
        font-size: 13px;
        background: none;
        color: black;
        border: 3px solid black;
        transition: 200ms ease;
        &:hover {
          background: black;
          color: white;
        }
      }
    }
  }
  .logo-container {
    margin-bottom: 40px;
    padding: 15px;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    svg,
    img {
      max-width: 120px;
      margin: auto;
    }
  }
  .content {
    margin: 55px auto 10px;
    max-width: 450px;
    p {
      font-size: 16px;
    }
  }
  .disclaimer-text {
    p {
      font-size: 12px;
      line-height: 16px;
      max-width: 500px;
      margin: 35px auto 0 auto;
    }
  }
}

@media only screen and (min-width: 1070px) {
  .dialog-wrapper {
    .button-container {
      flex-direction: row;
      margin-top: 30px;
      .sunny-logo,
      .jane-logo {
        display: block;
      }
      .sunny-logo {
        width: 198px;
        margin-bottom: 35px;
      }
      .jane-logo {
        width: 104px;
      }
      .shop-sunny {
        border-right: 1px solid black;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        padding: 15px 35px 0 35px;
      }
      .shop-jane {
        padding: 15px 35px 0 35px;
      }
      .shop-jane-gold {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 35px;
      }

      a {
        button {
          font-size: 16px;
        }
      }
    }
    .logo-container {
      margin-bottom: 20px;
      svg {
        max-width: 200px;
      }
    }
    .content {
      margin-bottom: 50px;
      margin-top: 75px;
      p {
        font-size: 20px;
      }
    }
    .disclaimer-text {
      margin-top: 50px;
    }
  }
}

.faq-popover .MuiPopover-paper {
  bottom: 20px;
  right: 20px;
}

.shop-page-dialog .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shop-page-dialog .MuiDialog-paperWidthSm {
  max-width: 1280px !important;
}

.shop-page-dialog .MuiBackdrop-root {
  background: none !important;
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  background: url(../images/texture.jpg);
  padding: 20px;
  text-align: center;
}

.dialog-wrapper .close {
  position: absolute;
  bottom: 22px;
  right: 16px;
}

.dialog-wrapper .close button {
  padding: 0;
}

.dialog-wrapper .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.dialog-wrapper .button-container .sunny-logo,
.dialog-wrapper .button-container .jane-logo {
  margin: 0 auto 25px;
  display: block;
}

.dialog-wrapper .button-container .sunny-logo {
  width: 150px;
}

.dialog-wrapper .button-container .jane-logo {
  width: 86px;
}

.dialog-wrapper .button-container .jane-gold-logo {
  width: 130px;
  margin: 0 auto 15px;
  display: block;
}

.dialog-wrapper .button-container .shop-sunny {
  border-bottom: 1px solid black;
  padding-bottom: 35px;
  margin-bottom: 32px;
}

.dialog-wrapper .button-container a {
  text-decoration: none;
}

.dialog-wrapper .button-container a button {
  font-size: 13px;
  background: none;
  color: black;
  border: 3px solid black;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

.dialog-wrapper .button-container a button:hover {
  background: black;
  color: white;
}

.dialog-wrapper .logo-container {
  margin-bottom: 40px;
  padding: 15px;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dialog-wrapper .logo-container svg,
.dialog-wrapper .logo-container img {
  max-width: 120px;
  margin: auto;
}

.dialog-wrapper .content {
  margin: 55px auto 10px;
  max-width: 450px;
}

.dialog-wrapper .content p {
  font-size: 16px;
}

.dialog-wrapper .disclaimer-text p {
  font-size: 12px;
  line-height: 16px;
  max-width: 500px;
  margin: 35px auto 0 auto;
}

@media only screen and (min-width: 1070px) {
  .dialog-wrapper .button-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
  }
  .dialog-wrapper .button-container .sunny-logo,
  .dialog-wrapper .button-container .jane-logo {
    display: block;
  }
  .dialog-wrapper .button-container .sunny-logo {
    width: 198px;
    margin-bottom: 35px;
  }
  .dialog-wrapper .button-container .jane-logo {
    width: 104px;
  }
  .dialog-wrapper .button-container .shop-sunny {
    border-right: 1px solid black;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container .shop-jane {
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container .shop-jane-gold {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 0 35px;
  }
  .dialog-wrapper .button-container a button {
    font-size: 16px;
  }
  .dialog-wrapper .logo-container {
    margin-bottom: 20px;
  }
  .dialog-wrapper .logo-container svg {
    max-width: 200px;
  }
  .dialog-wrapper .content {
    margin-bottom: 50px;
    margin-top: 75px;
  }
  .dialog-wrapper .content p {
    font-size: 20px;
  }
  .dialog-wrapper .disclaimer-text {
    margin-top: 50px;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "neue-haas-unica", sans-serif !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}

.mr-10 {
  margin-right: 20px !important;
}

.mr-15 {
  margin-right: 30px !important;
}

.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}

.ml-10 {
  margin-left: 20px !important;
}

.ml-15 {
  margin-left: 30px !important;
}

.ml-20 {
  margin-left: 40px !important;
}

.block {
  display: block !important;
}

.wh-100 {
  height: 100%;
  width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.standard-padding,
.less-standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding,
  .less-standard-padding {
    padding: 50px;
  }
}

input,
textarea,
select {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0 !important;
}

input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

img {
  display: block;
}

h1 {
  margin: 0;
  margin-bottom: 16px;
  white-space: pre-wrap;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}

h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}

h3 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}

p {
  margin: 0;
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.29;
  letter-spacing: normal;
  font-family: "neue-haas-unica", sans-serif !important;
  margin-bottom: 20px;
}

ul,
ol {
  padding-left: 30px;
  margin: 0px;
  margin-bottom: 20px;
}

img {
  display: block;
}

a {
  color: black;
}

.smaller-text,
.smaller-text p {
  font-size: 14px;
  max-width: 320px;
  margin: auto;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 32px;
  }
  p,
  ul,
  ol {
    font-size: 20px;
  }
}

.hero-section .buttons a {
  text-decoration: none !important;
}

.hero-section .buttons button {
  border: 2px solid white;
  color: white;
  margin-right: 15px;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

.hero-section .buttons button:hover {
  background-color: white;
  color: black;
}

.generic-modal .modalWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.generic-modal div div {
  width: 100% !important;
  height: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  position: relative;
  overflow: hidden;
}

.generic-modal div div .icon-close {
  background: none !important;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.generic-modal .videoWrapper {
  position: relative;
  display: block;
  width: 100% !important;
  height: auto !important;
}

@media only screen and (max-width: 600px) {
  .generic-modal .youtubeIframe {
    height: 50%;
  }
}

button {
  display: inline-block;
  -webkit-appearance: none;
  background: none;
  border: none;
}

.button {
  display: inline-block;
  -webkit-appearance: none;
  font-size: 12px !important;
  padding: 13px 26px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  background: none;
  border: 2px solid white;
  outline: none;
  letter-spacing: 1px;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.button.solid {
  background: black;
  border: 2px solid transparent;
}

.button.solid:hover {
  border: 2px solid black;
}

.button.white {
  background: white;
  color: black;
  border: 2px solid transparent;
}

.button.white:hover {
  border: 2px solid black;
  background: black;
  color: white;
}

.button.small {
  padding: 8px;
}

.button.wide {
  width: 100%;
  text-align: center;
}

.button:hover {
  background: white;
  color: black;
  cursor: pointer;
}

#age-gate {
  padding: 0px;
  background: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 100000;
}

#age-gate.error {
  background: red;
}

#age-gate .age-gate-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100% - 100px);
  padding: 50px 25px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

#age-gate .age-gate-content a {
  color: white;
  font-weight: bold;
}

#age-gate .age-gate-content .spanish-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

#age-gate .age-gate-content .age-gate-logo {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  max-width: 200px;
}

#age-gate .age-gate-content h2 {
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

#age-gate .age-gate-content form {
  margin-bottom: 20px;
  line-height: 1;
}

#age-gate .age-gate-content form .error-message {
  display: block;
}

@media only screen and (min-width: 768px) {
  #age-gate .age-gate-content {
    max-width: 500px;
    padding: 50px;
    height: calc(100% - 100px);
  }
}

.header-spacer {
  height: 60px;
}

header {
  padding: 20px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

header .logo {
  width: 200px;
  height: 20px;
  margin: 0 auto;
}

header .nav-menu {
  width: 24px;
  height: 20px;
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

header .nav-menu span {
  width: 100%;
  height: 3px;
  background: black;
  display: block;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  position: relative;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  top: 0;
}

header .nav-menu.close span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 10px;
}

header .nav-menu.close span:nth-of-type(2) {
  opacity: 0;
}

header .nav-menu.close span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -7px;
}

header #navigation-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100vh;
  width: 50%;
  padding: 60px 20px;
  padding-right: 60px;
  padding-top: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 100;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

header #navigation-container.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

header #navigation-container a {
  display: block;
  margin-bottom: 10px;
  font-size: 22px;
  color: black;
  text-transform: uppercase;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
}

header #navigation-container a.hide {
  display: none !important;
}

header #navigation-container a:hover {
  color: gray;
}

header #navigation-container a.active {
  color: #979797;
}

header #navigation-container a .dropdown {
  display: none;
}

header #navigation-container a .dropdown.dd-active {
  display: block;
}

header #navigation-container a .dropdown a:first-of-type {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #979797;
}

header #navigation-container .nav-close {
  width: 20px;
  height: 20px;
  background: black;
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
}

@media only screen and (min-width: 768px) {
  .header-spacer {
    height: 74px;
  }
  header .nav-menu {
    display: none;
  }
  header .logo {
    float: left;
    margin: 0;
    height: 100%;
    width: 140px;
  }
  header #navigation-container {
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
    -webkit-box-shadow: none;
            box-shadow: none;
    position: static;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
    transition: none;
    -webkit-transition: none;
    float: right;
  }
  header #navigation-container a {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    -webkit-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    position: relative;
  }
  header #navigation-container a .dropdown {
    display: none;
    position: absolute;
    top: 44px;
    right: 0;
    width: 150px;
    text-align: right;
    background: white;
    z-index: 20;
    padding: 10px;
  }
  header #navigation-container a .dropdown.dd-active {
    display: block;
  }
  header #navigation-container a .dropdown a:first-of-type {
    padding-top: 0;
    margin: 0 !important;
    border-top: none;
  }
}

.hero-section {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-section.newsletter, .hero-section.not-newsletter {
  margin-top: 0;
}

.hero-section.newsletter .wrapper .text-content, .hero-section.not-newsletter .wrapper .text-content {
  min-height: auto;
}

.hero-section.newsletter .wrapper .text-content .buttons, .hero-section.not-newsletter .wrapper .text-content .buttons {
  margin-top: 20px;
  margin-left: 0;
}

.hero-section.simple .wrapper .text-content {
  min-height: auto;
}

.hero-section .background,
.hero-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-section .overlay {
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
}

.hero-section .background {
  z-index: 1;
  background-size: cover !important;
  background-position: center center !important;
}

.hero-section .wrapper .text-content {
  z-index: 3;
  position: relative;
  padding: 50px 25px;
  min-height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.hero-section .wrapper .text-content h1,
.hero-section .wrapper .text-content h2 {
  color: white;
}

.hero-section .wrapper .text-content p {
  color: white;
}

.hero-section .wrapper .text-content p img.logo-text {
  display: inline;
  height: 20px;
  width: auto;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.hero-section .wrapper .text-content .buttons {
  margin-top: auto;
}

.hero-section .wrapper .text-content .buttons .button {
  margin-right: 10px;
  margin-top: 10px;
}

.hero-section .wrapper .text-content .buttons .button:last-of-type {
  margin-right: 0;
}

.hero-section .wrapper .text-content .mailchimp-form {
  margin-top: 20px;
  display: block;
  text-align: right;
}

.hero-section .wrapper .text-content .mailchimp-form div {
  color: white !important;
  margin-bottom: 20px;
  font-size: 12px;
}

.hero-section .wrapper .text-content .mailchimp-form ::-webkit-input-placeholder {
  color: #979797;
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.hero-section .wrapper .text-content .mailchimp-form :-ms-input-placeholder {
  color: #979797;
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.hero-section .wrapper .text-content .mailchimp-form ::-ms-input-placeholder {
  color: #979797;
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.hero-section .wrapper .text-content .mailchimp-form ::placeholder {
  color: #979797;
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.hero-section .wrapper .text-content .mailchimp-form input[type="email"] {
  border: 0;
  font-size: 12px !important;
  padding: 13px;
  outline: none;
  border: 2px solid white;
  border-radius: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.hero-section .wrapper .text-content .mailchimp-form button {
  display: inline-block;
  margin-top: 15px;
  background: white;
  color: black;
}

.hero-section .wrapper .text-content .mailchimp-form button:hover {
  background: black;
  color: white;
  border: 2px solid black;
}

@media only screen and (min-width: 600px) {
  .hero-section.newsletter .wrapper .text-content {
    padding: 200px 50px;
    max-width: 50%;
    padding-left: 0;
  }
  .hero-section.left-aligned .wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .hero-section.right-aligned .wrapper {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .hero-section.solid-background .wrapper {
    max-width: none;
  }
  .hero-section.solid-background .wrapper .text-content {
    background: black;
  }
  .hero-section.solid-background .overlay {
    display: none;
  }
  .hero-section.background-50 .background {
    width: 50%;
  }
  .hero-section.background-50 .wrapper .text-content {
    width: 50% !important;
  }
  .hero-section .overlay {
    display: none;
  }
  .hero-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .hero-section .wrapper .text-content {
    padding: 170px 50px;
    width: auto;
    max-width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: auto;
    min-width: 400px;
    text-align: left;
  }
  .hero-section .wrapper .text-content .mailchimp-form {
    text-align: left;
  }
  .hero-section .wrapper .text-content .mailchimp-form .button {
    display: inline-block;
    margin-top: 0;
    margin-left: 10px;
  }
  .hero-section .wrapper .text-content .mailchimp-form input[type="email"] {
    width: auto;
    margin-right: 10px;
  }
  .hero-section .wrapper .text-content .buttons {
    margin-left: 0;
    margin-top: 20px;
  }
}

.section-half {
  padding: 50px 25px;
}

.section-half .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.section-half .wrapper .img-content img {
  display: block;
  width: 100%;
  height: auto;
}

.section-half .wrapper .text-content {
  margin-bottom: 32px;
}

@media only screen and (min-width: 600px) {
  .section-half {
    padding: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .section-half {
    padding: 100px 50px;
  }
  .section-half .wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .section-half .wrapper .text-content,
  .section-half .wrapper .img-content {
    width: 50%;
  }
  .section-half .wrapper .text-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 0;
    margin-left: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

#landing-page #logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 40%;
  height: auto;
}

#landing-page #logo img {
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: 768px) {
  #landing-page #logo {
    width: 20%;
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
}

.products .section-description {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.products .button {
  margin-top: 20px;
}

.products .product-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: end;
      align-content: flex-end;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.products .product-icons .product-icon {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 50%;
  margin: 20px 0px;
}

.products .product-icons .product-icon img {
  height: auto;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center bottom;
     object-position: center bottom;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 200px;
}

.products .product-icons .product-icon h3 {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
  }
  .products .product-icons .product-icon {
    width: auto;
  }
}

.mr-10 {
  margin-right: 10px;
}

footer {
  background: #110f10;
  background-size: cover !important;
  background-position: center center !important;
  color: white;
}

footer .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .wrapper .footer-section {
  margin-bottom: 30px;
  width: 50%;
}

footer .wrapper .footer-section h2 {
  font-size: 20px;
}

footer .wrapper .footer-section p {
  font-size: 14px;
  margin-bottom: 10px;
}

footer .wrapper .footer-section p:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section .tagline {
  margin-bottom: 10px !important;
}

footer .wrapper .footer-section .tagline .logo-text {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 8px;
  white-space: nowrap;
}

footer .wrapper .footer-section .social {
  margin-top: 20px;
}

footer .wrapper .footer-section .social a {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: auto;
  margin-bottom: 0;
}

footer .wrapper .footer-section .social a img {
  width: 100%;
  height: auto;
}

footer .wrapper .footer-section .social .copyright p {
  margin-bottom: 20px;
  font-weight: 900;
}

footer .wrapper .footer-section a {
  display: block;
  color: black;
  margin-bottom: 10px;
  text-decoration: none;
}

footer .wrapper .footer-section a:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section:first-of-type, footer .wrapper .footer-section:last-of-type {
  width: 100%;
}

footer .wrapper .footer-section:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  footer .wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  footer .wrapper .footer-section {
    margin-right: 20px;
    width: auto;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .wrapper .footer-section .social {
    margin: 0;
  }
  footer .wrapper .footer-section:last-of-type {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

.map-section {
  background-size: cover !important;
  background-position: center center;
  padding: 50px 25px;
}

.map-section .buttons {
  margin-top: 20px;
}

@media only screen and (min-width: 600px) {
  .map-section {
    padding: 100px 50px;
  }
}

.instagram-section {
  padding: 50px 25px;
}

.instagram-section .photos {
  margin-top: 25px;
}

.instagram-section .photos a {
  margin-bottom: 10px;
  display: block;
}

.instagram-section .photos a img {
  width: 100%;
  height: auto;
  display: block;
}

.instagram-section .photos a:last-of-type {
  margin-bottom: 0;
}

.instagram-section .photos img {
  display: none;
  width: 100%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.instagram-section .photos img:first-of-type {
  display: block;
}

.video-thumb-slice .content p img.logo-text {
  display: inline;
  height: 20px;
  width: auto;
}

.black_back {
  background: black;
  color: white;
}

.black_back .button {
  border: 2px solid white;
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
  }
  .instagram-section .flex-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }
  .instagram-section .flex-wrapper .content {
    width: 100%;
    margin-right: 20px;
  }
  .instagram-section .flex-wrapper .button {
    margin-bottom: 25px;
  }
  .instagram-section .photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    clear: both;
  }
  .instagram-section .photos a {
    display: block;
    width: calc(100% / 3 - 20px);
    margin-right: 30px;
  }
  .instagram-section .photos a:last-of-type {
    margin-right: 0;
  }
  .instagram-section .photos a img {
    width: 100%;
    height: auto;
  }
  .instagram-section .photos img {
    display: block;
    width: calc(100% / 3 - 20px);
    margin-right: 30px;
  }
  .instagram-section .photos img:last-of-type {
    margin-right: 0;
  }
}

.products-page-section {
  min-height: 400px;
}

.products-page-section .wrapper .carousel-container {
  padding: 25px;
}

.products-page-section .wrapper .carousel-container .options span {
  display: inline-block;
  padding: 10px;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 16px;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.products-page-section .wrapper .carousel-container .options span:last-of-type {
  margin-right: 0;
}

.products-page-section .wrapper .carousel-container .options span.active {
  background: black;
  color: white;
}

.products-page-section .wrapper .carousel-container .options span:hover {
  background: #979797;
  color: white;
}

.products-page-section .wrapper .carousel-container .form-info-container .selected-form {
  width: 60%;
  height: auto;
  margin: 25px auto;
}

.products-page-section .wrapper .carousel-container .form-info-container .text-content p:last-of-type {
  margin-top: 15px;
}

.products-page-section .wrapper .product-carousel {
  position: relative;
}

.products-page-section .wrapper .product-carousel .images {
  width: 100%;
  height: 50vh;
}

.products-page-section .wrapper .product-carousel .images .product-image {
  width: 100%;
  height: 100%;
  display: none;
}

.products-page-section .wrapper .product-carousel .images .product-image img {
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: center center !important;
     object-position: center center !important;
}

.products-page-section .wrapper .product-carousel .images .product-image.active {
  display: block;
}

.products-page-section .wrapper .product-carousel .controls {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
}

.products-page-section .wrapper .product-carousel .controls div {
  background: white;
  border-radius: 100%;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.products-page-section .wrapper .product-carousel .controls div:hover {
  background: #979797;
  color: white;
}

.products-page-section .wrapper .product-carousel .controls .forward:before {
  content: ">";
}

.products-page-section .wrapper .product-carousel .controls .back {
  margin-right: 20px;
}

.products-page-section .wrapper .product-carousel .controls .back:before {
  content: "<";
}

@media only screen and (min-width: 600px) {
  .products-page-section .wrapper .carousel-container {
    padding: 25px 50px;
  }
  .products-page-section .wrapper .carousel-container .options {
    text-align: center;
  }
  .products-page-section .wrapper .carousel-container .form-info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .products-page-section .wrapper .carousel-container .form-info-container .selected-form {
    margin-right: 25px;
  }
  .products-page-section .wrapper .carousel-container .form-info-container .text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 25px 0px;
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
  }
  .products-page-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .products-page-section .wrapper .product-carousel {
    min-width: 60%;
    margin: 0;
  }
  .products-page-section .wrapper .product-carousel .images {
    height: 100%;
  }
  .products-page-section .wrapper .carousel-container {
    padding: 0;
    padding-right: 25px;
    min-width: 25%;
  }
  .products-page-section .wrapper .carousel-container .options {
    text-align: left;
  }
  .products-page-section .wrapper .carousel-container .form-info-container {
    display: block;
  }
  .products-page-section .wrapper .carousel-container .form-info-container .selected-form {
    margin: 25px auto;
    width: 100%;
  }
  .products-page-section .wrapper .carousel-container .form-info-container .text-content {
    padding: 0;
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
  }
  .simple-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .simple-section .wrapper h2 {
    margin-right: 10%;
  }
}

.simple-page {
  padding: 25px;
}

.simple-page p,
.simple-page li,
.simple-page a {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: black;
  letter-spacing: normal;
}

.simple-page p:last-of-type,
.simple-page li:last-of-type,
.simple-page a:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
  }
}

.no-flick {
  min-height: 80vh;
}

.animation {
  opacity: 0;
}

.animation.yas {
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  opacity: 1;
}

.pdf-section .react-pdf__Document {
  pointer-events: none;
  -webkit-user-select: none;
  overflow: hidden;
}

.pdf-section .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdf-section .pdf-navigation {
  text-align: center;
  z-index: 20;
  position: relative;
}

.pdf-section .pdf-navigation .page-indicator {
  margin-bottom: 10px;
}

.pdf-section .pdf-navigation .button:first-of-type {
  margin-right: 10px;
}

.pdf-section .pdf-navigation .button {
  z-index: 20;
  color: white;
}

.pdf-section .pdf-navigation .button:hover {
  color: black;
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
}

.map-wrapper .interactive-map {
  width: 100%;
  display: block;
  position: relative !important;
  display: none;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  height: calc(100vh - 73px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  margin-top: 10px;
  padding: 0px 20px;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #979797;
  color: white;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .locations-list-wrapper .info-message {
  background: #000000;
  color: white;
  padding: 8px 20px;
}

.map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.map-wrapper .locations-list-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location.active {
  background: rgba(0, 0, 0, 0.2);
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #000000;
  width: 100%;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  color: #000000;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: #000000;
}

@media only screen and (min-width: 700px) {
  .map-wrapper {
    overflow: auto;
  }
  .map-wrapper .interactive-map {
    height: calc(100vh - 73px) !important;
    width: 60% !important;
    float: right;
    display: block;
  }
  .map-wrapper .stores-wrapper {
    height: calc(100vh - 73px) !important;
    width: 40%;
    float: left;
  }
}

.gm-style {
  font: inherit !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
}

.info-window-content .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000000;
}

.info-window-content .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.info-window-content .usage-type {
  font-size: 16px;
  margin-bottom: 10px;
}

.info-window-content a {
  text-decoration: none;
}

#alert-dialog-description p a {
  color: black;
  text-decoration: underline;
}

.full-width-slice .wrapper .text-content {
  max-width: 600px;
}

.full-width-slice.align-right .wrapper .text-content {
  margin-left: auto;
}

.video-single {
  margin-top: 60px;
}

.video-single .video-and-description .video-embed-container iframe {
  width: 100%;
  height: 500px;
}

.video-single .video-and-description .video-description {
  padding: 20px;
}

.video-single .related-videos {
  padding: 20px;
  background: black;
}

.video-single .related-videos h2 {
  color: white;
}

.video-single .related-videos .related-videos-container {
  overflow: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.video-single .related-videos .related-videos-container a {
  text-decoration: none;
  color: white;
  margin-right: 20px;
}

.video-single .related-videos .related-videos-container a img {
  width: 200px;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  margin-bottom: 10px;
}

@media only screen and (min-width: 960px) {
  .video-single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .video-single .related-videos {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: scroll;
  }
  .video-single .related-videos .related-videos-container {
    display: block;
    overflow: hidden;
  }
  .video-single .related-videos .related-videos-container a {
    margin-right: 0;
  }
  .video-single .related-videos .related-videos-container a:last-of-type {
    margin-bottom: 0;
  }
  .video-single .related-videos .related-videos-container a:last-of-type h3 {
    margin-bottom: 0;
  }
  .video-single .related-videos .related-videos-container a img {
    width: 100%;
    height: 150px;
  }
}

.video-list-slice .video-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(320px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 1400px;
  grid-gap: 1rem;
  margin: auto;
}

@media only screen and (max-width: 350px) {
  .video-list-slice .video-grid {
    -ms-grid-columns: (minmax(260px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

.video-list-slice .video-grid .video-list-item {
  margin: 0 auto;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.video-list-slice .video-grid .video-list-item:hover {
  opacity: 0.8;
}

.video-list-slice .video-grid .video-list-item .image-content {
  position: relative;
  cursor: pointer;
}

.video-list-slice .video-grid .video-list-item .image-content:before {
  content: "";
  position: absolute;
  background: url(../images/icons/play-arrow.svg) no-repeat;
  height: 30px;
  width: 30px;
  z-index: 20;
  top: 47%;
  left: 47%;
}

.video-list-slice .video-grid .video-list-item img {
  width: 90%;
  margin: auto;
  max-width: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
}

.video-list-slice .video-grid .video-list-item .text-content {
  padding: 20px 0px;
}

.video-list-slice .video-grid .video-list-item .text-content img {
  width: 70%;
  height: auto;
  margin: 0 auto 20px;
}

.video-list-slice .video-grid .video-list-item .text-content h2 {
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
  min-height: 36px;
}

.video-list-slice .video-grid .video-list-item .text-content .button {
  margin-bottom: 0;
}

.filters {
  text-align: center;
  margin-bottom: 2rem;
}

.filters .sort {
  text-align: center;
}

.filters .filter-copy p:first-child {
  margin-bottom: 0;
  padding-top: 1rem;
}

@media only screen and (min-width: 600px) {
  .filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .filters .filters-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .filters .filter-copy {
    max-width: 600px;
    margin: auto;
  }
  .filters .sort {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-right: 2rem;
    font-size: 1.5rem;
  }
}

.playVideo {
  margin: 0;
  padding: 0;
  color: black;
}

@media only screen and (min-width: 960px) {
  .video-list-slice .video-list-item {
    margin-bottom: 20px;
  }
  .video-list-slice .video-list-item:last-of-type {
    margin-bottom: 0;
  }
  .video-list-slice .video-list-item img {
    height: 100%;
    max-width: none;
  }
  .video-list-slice .video-list-item .text-content {
    padding: 20px;
  }
  .iframe-slice {
    width: 100%;
    margin-bottom: 100px;
  }
  .iframe-slice iframe {
    width: 100%;
    height: 50vh;
    border: 0;
  }
}

.faq-section .question {
  cursor: pointer;
}

.faq-section table h2,
.faq-section table h3,
.faq-section table h4,
.faq-section table h5,
.faq-section table h6 {
  margin: 0 !important;
}

.faq-section table div {
  margin: 0;
}

.faq-section table .answer {
  padding: 20px;
}

.standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 100px 50px;
  }
}

.button-container {
  margin-top: 35px;
}

.button-container a button {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.button-container a:last-of-type() button {
  margin-bottom: 0px;
}

@media only screen and (min-width: 900px) {
  .button-container {
    margin-top: 35px;
  }
  .button-container a button {
    margin-left: 10px;
    margin-bottom: 0;
    display: inline-block;
  }
  .button-container a:nth-of-type(1) button {
    margin-left: 0px;
  }
}

#contact-page .wrapper {
  max-width: 700px;
  padding: 20px;
}

#contact-header {
  background: black;
}

#contact-header h1 {
  text-align: center;
  padding: 40px 0px;
  color: white;
  margin: 0;
}

.form-message {
  display: block;
  background: black;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.form-message p {
  color: inherit;
  margin: 0;
}

.form-message a {
  text-decoration: underline;
  color: inherit;
}

.form-message.error {
  color: #ad0000;
  background: none;
  border: 1px solid #ad0000;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.info-box-slice {
  text-align: center;
}

.info-box-slice .wrapper {
  margin: auto;
  padding: 40px;
}

.info-box-slice .wrapper h2 {
  margin-bottom: 40px;
}

.info-box-slice .info-boxes {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 16px;
}

@media only screen and (min-width: 600px) {
  .info-box-slice .info-boxes {
    -ms-grid-columns: (minmax(146px, 239px))[auto-fill];
        grid-template-columns: repeat(auto-fill, minmax(146px, 239px));
  }
}

.info-box-slice .box-wrapper {
  margin-bottom: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.info-box-slice .box-wrapper h2 {
  font-size: 16px;
  margin-bottom: 0;
}

.info-box-slice .box-wrapper p,
.info-box-slice .box-wrapper ul {
  font-size: 14px;
  max-width: 280px;
  margin: auto;
}

.info-box-slice .box-wrapper ul {
  margin-top: 20px;
  text-align: left;
  display: inline-block;
}

.info-box-slice .box-wrapper ul li {
  margin-bottom: 20px;
}

.info-box-slice .box-wrapper .box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: black;
  color: white;
  width: 100%;
  width: 146px;
  height: 131px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 28px 12px;
  margin: 0 auto 20px;
}

@media only screen and (min-width: 600px) {
  .info-box-slice .wrapper .content {
    margin-bottom: 30px;
  }
  .info-box-slice .box-wrapper h2 {
    font-size: 22px;
  }
  .info-box-slice .box-wrapper .box {
    height: 214px;
    width: 100%;
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid white;
}

.info-wrapper {
  background-color: black;
  color: white;
}

.info-wrapper.not-expanded {
  padding: 15px;
}

.info-wrapper.expanded {
  padding: 30px;
}

.info-wrapper .toggle-button {
  color: white;
  padding: 0;
  margin: 0;
}

.info-wrapper .toggle-button h2 {
  margin-bottom: 10px;
}

.info-wrapper .toggle-button.left {
  text-align: left;
}

.info-wrapper .toggle-button.center {
  text-align: center;
}

.info-wrapper .toggle-button .triangle-down {
  margin: auto;
}

.info-wrapper .collapse-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.info-wrapper .collapse-wrapper button {
  color: white;
}

.info-wrapper .collapse-wrapper button .triangle-up {
  margin: auto;
  margin-bottom: 10px;
}

.info-wrapper .toggled-info a {
  color: white;
  text-decoration: underline;
}

.info-wrapper .collapse,
.info-wrapper .toggle-button {
  cursor: pointer;
}

@media only screen and (min-width: 800px) {
  .info-drop-down-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 60px auto;
  }
  .info-wrapper {
    display: inline-block;
    max-width: 800px;
    margin: auto;
  }
  .info-wrapper.not-expanded {
    padding: 15px 30px;
  }
  .info-wrapper.expanded {
    padding: 60px;
  }
  .info-wrapper.expanded p,
  .info-wrapper.expanded ol,
  .info-wrapper.expanded ul {
    font-size: 14px;
  }
  .info-wrapper.expanded h3,
  .info-wrapper.expanded h4 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .info-wrapper .toggle-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .info-wrapper .toggle-button h2 {
    font-size: 22px;
  }
  .info-wrapper .toggle-button .triangle-down {
    margin-bottom: 15px;
    margin-left: 15px;
  }
}

#budtender.iframe-slice {
  padding: 0 40px;
  margin: 20px auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#budtender.iframe-slice .copy-wrapper {
  text-align: center;
  margin: 40px auto 20px;
}

#budtender.iframe-slice .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#budtender.iframe-slice .wrapper iframe {
  height: 900px;
  width: 100%;
  max-width: 700px;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

$gray: #979797;
$errorRed: #ad0000;

@import "imports/modal";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "neue-haas-unica", sans-serif !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 20px !important;
}
.mr-15 {
  margin-right: 30px !important;
}
.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 20px !important;
}
.ml-15 {
  margin-left: 30px !important;
}
.ml-20 {
  margin-left: 40px !important;
}

.block {
  display: block !important;
}

.wh-100 {
  height: 100%;
  width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.standard-padding,
.less-standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding,
  .less-standard-padding {
    padding: 50px;
  }
}

input,
textarea,
select {
  box-shadow: none !important;
  border-radius: 0 !important;
}

input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

img {
  display: block;
}
h1 {
  margin: 0;
  margin-bottom: 16px;
  white-space: pre-wrap;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}
h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}
h3 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: uppercase;
  font-family: "neue-haas-unica", sans-serif;
}
p {
  margin: 0;
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.29;
  letter-spacing: normal;
  font-family: "neue-haas-unica", sans-serif !important;
  margin-bottom: 20px;
}
ul,
ol {
  padding-left: 30px;
  margin: 0px;
  margin-bottom: 20px;
}
img {
  display: block;
}

a {
  // text-decoration: none;
  color: black;
}
.smaller-text,
.smaller-text p {
  font-size: 14px;
  max-width: 320px;
  margin: auto;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 32px;
  }
  p,
  ul,
  ol {
    font-size: 20px;
  }
}

.hero-section {
  .buttons {
    a {
      text-decoration: none !important;
    }
    button {
      border: 2px solid white;
      color: white;
      margin-right: 15px;
      transition: 200ms ease;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

//GENERIC MODAL

.generic-modal {
  .modalWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  div {
    div {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      position: relative;
      overflow: hidden;
      .icon-close {
        background: none !important;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
      }
    }
  }

  .videoWrapper {
    position: relative;
    display: block;
    width: 100% !important;
    height: auto !important;
    // padding-bottom: 56.25%;
  }
  .youtubeIframe {
    @media only screen and (max-width: 600px) {
      height: 50%;
    }
  }
}

//AGE GATE
button {
  display: inline-block;
  -webkit-appearance: none;
  background: none;
  border: none;
}

.button {
  display: inline-block;
  -webkit-appearance: none;
  font-size: 12px !important;
  padding: 13px 26px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  background: none;
  border: 2px solid white;
  outline: none;
  letter-spacing: 1px;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  &.solid {
    background: black;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid black;
    }
  }
  &.white {
    background: white;
    color: black;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid black;
      background: black;
      color: white;
    }
  }
  &.small {
    padding: 8px;
  }
  &.wide {
    width: 100%;
    text-align: center;
  }
  &:hover {
    background: white;
    color: black;
    cursor: pointer;
  }
}

#age-gate {
  padding: 0px;
  background: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 100000;
  &.error {
    background: red;
  }
  .age-gate-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    padding: 50px 25px;
    justify-content: flex-end;
    a {
      color: white;
      font-weight: bold;
    }
    .spanish-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
    .age-gate-logo {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
      max-width: 200px;
    }
    h2 {
      margin: 20px 0px;
      font-size: 20px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    }
    form {
      margin-bottom: 20px;
      line-height: 1;
      .error-message {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #age-gate {
    .age-gate-content {
      max-width: 500px;
      padding: 50px;
      height: calc(100% - 100px);
    }
  }
}

//HEADER

.header-spacer {
  height: 60px;
}

header {
  padding: 20px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  .logo {
    width: 200px;
    height: 20px;
    margin: 0 auto;
  }
  .nav-menu {
    width: 24px;
    height: 20px;
    display: block;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      width: 100%;
      height: 3px;
      background: black;
      display: block;
      transform-origin: center center;
      position: relative;
      transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      top: 0;
    }
    &.close {
      span:nth-of-type(1) {
        transform: rotate(45deg);
        top: 10px;
      }
      span:nth-of-type(2) {
        opacity: 0;
      }
      span:nth-of-type(3) {
        transform: rotate(-45deg);
        top: -7px;
      }
    }
  }
  #navigation-container {
    &.open {
      transform: translateX(0);
    }
    transform: translateX(-100%);
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    height: 100vh;
    width: 50%;
    padding: 60px 20px;
    padding-right: 60px;
    padding-top: 100px;
    box-sizing: border-box;
    z-index: 100;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    a {
      display: block;
      margin-bottom: 10px;
      font-size: 22px;
      color: black;
      text-transform: uppercase;
      font-weight: 900;
      cursor: pointer;
      text-decoration: none;
      &.hide {
        display: none !important;
      }
      &:hover {
        color: gray;
      }
      &.active {
        color: $gray;
      }
      .dropdown {
        display: none;
        &.dd-active {
          display: block;
        }
        a {
          &:first-of-type {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid $gray;
          }
        }
      }
    }
    .nav-close {
      width: 20px;
      height: 20px;
      background: black;
      display: block;
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .header-spacer {
    height: 74px;
  }
  header {
    // overflow: auto;
    .nav-menu {
      display: none;
    }
    .logo {
      float: left;
      margin: 0;
      height: 100%;
      width: 140px;
    }
    #navigation-container {
      transform: translateX(0) !important;
      box-shadow: none;
      position: static;
      background: none;
      width: auto;
      height: auto;
      padding: 0;
      transition: none;
      -webkit-transition: none;
      a {
        margin-bottom: 0;
        display: inline-block;
        margin-right: 30px;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        -webkit-transition: all 100ms ease-in-out;
        transition: all 100ms ease-in-out;
        position: relative;
        .dropdown {
          display: none;
          position: absolute;
          top: 44px;
          right: 0;
          width: 150px;
          text-align: right;
          background: white;
          z-index: 20;
          padding: 10px;
          &.dd-active {
            display: block;
          }
          a {
            &:first-of-type {
              padding-top: 0;
              margin: 0 !important;
              border-top: none;
            }
          }
        }
      }
      float: right;
    }
  }
}

.hero-section {
  position: relative;
  height: 100%;
  width: 100%;
  &.newsletter,
  &.not-newsletter {
    margin-top: 0;
    .wrapper {
      .text-content {
        min-height: auto;
        .buttons {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }
  &.simple {
    .wrapper {
      .text-content {
        min-height: auto;
      }
    }
  }
  .background,
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
  }
  .background {
    z-index: 1;
    background-size: cover !important;
    background-position: center center !important;
  }
  .wrapper {
    .text-content {
      z-index: 3;
      position: relative;
      padding: 50px 25px;
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      h1,
      h2 {
        color: white;
      }
      p {
        color: white;
        img.logo-text {
          display: inline;
          height: 20px;
          width: auto;
          filter: brightness(0) invert(1);
        }
      }
      .buttons {
        // margin: 0 auto;
        margin-top: auto;
        .button {
          margin-right: 10px;
          margin-top: 10px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .mailchimp-form {
        margin-top: 20px;
        display: block;
        text-align: right;
        div {
          color: white !important;
          margin-bottom: 20px;
          font-size: 12px;
        }
        ::placeholder {
          color: $gray;
          font-family: "neue-haas-unica", sans-serif;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
        }
        input[type="email"] {
          border: 0;
          font-size: 12px !important;
          padding: 13px;
          outline: none;
          border: 2px solid white;
          border-radius: 0;
          box-shadow: none !important;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: inline-block;
          margin-top: 15px;
          background: white;
          color: black;
          &:hover {
            background: black;
            color: white;
            border: 2px solid black;
          }
          // margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .hero-section {
    &.newsletter {
      .wrapper {
        .text-content {
          padding: 200px 50px;
          max-width: 50%;
          padding-left: 0;
        }
      }
    }
    &.left-aligned {
      .wrapper {
        justify-content: flex-start;
      }
    }
    &.right-aligned {
      .wrapper {
        justify-content: flex-end;
      }
    }
    &.solid-background {
      .wrapper {
        max-width: none;
        .text-content {
          background: black;
        }
      }
      .overlay {
        display: none;
      }
    }
    &.background-50 {
      .background {
        width: 50%;
      }
      .wrapper {
        .text-content {
          width: 50% !important;
        }
      }
    }
    .overlay {
      display: none;
    }
    .wrapper {
      display: flex;
      .text-content {
        padding: 170px 50px;
        width: auto;
        max-width: 50%;
        box-sizing: border-box;
        min-height: auto;
        min-width: 400px;
        text-align: left;
        .mailchimp-form {
          text-align: left;
          .button {
            display: inline-block;
            margin-top: 0;
            margin-left: 10px;
          }
          input[type="email"] {
            width: auto;
            margin-right: 10px;
          }
        }
        .buttons {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
}

//Section

.section-half {
  padding: 50px 25px;
  .wrapper {
    display: flex;
    flex-direction: column;

    .img-content {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .text-content {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .section-half {
    padding: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .section-half {
    padding: 100px 50px;
    .wrapper {
      flex-direction: row;
      .text-content,
      .img-content {
        width: 50%;
      }
      .text-content {
        order: 2;
        margin-bottom: 0;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;

  #logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 40%;
    height: auto;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) {
  #landing-page {
    #logo {
      width: 20%;
    }
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
  .section-description {
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .button {
    margin-top: 20px;
  }

  .product-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-end;
    box-sizing: border-box;
    .product-icon {
      text-align: center;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      width: 50%;
      margin: 20px 0px;
      img {
        height: auto;
        width: 100%;
        object-fit: contain;
        object-position: center bottom;
        margin: 0 auto;
        margin-bottom: 20px;
        max-width: 200px;
      }
      h3 {
        color: white;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
    .product-icons {
      .product-icon {
        width: auto;
      }
    }
  }
}

.mr-10 {
  margin-right: 10px;
}

footer {
  background: #110f10;
  background-size: cover !important;
  background-position: center center !important;
  color: white;
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .footer-section {
      margin-bottom: 30px;
      width: 50%;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .tagline {
        margin-bottom: 10px !important;
        .logo-text {
          font-weight: 900;
          font-size: 16px;
          letter-spacing: 8px;
          white-space: nowrap;
        }
      }
      .social {
        margin-top: 20px;
        a {
          display: inline-block;
          margin-right: 10px;
          width: 20px;
          height: auto;
          margin-bottom: 0;
          img {
            width: 100%;
            height: auto;
          }
        }
        .copyright {
          p {
            margin-bottom: 20px;
            font-weight: 900;
          }
        }
      }
      a {
        display: block;
        color: black;
        margin-bottom: 10px;
        text-decoration: none;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  footer {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      .footer-section {
        margin-right: 20px;
        width: auto;
        order: 1;
        .social {
          margin: 0;
        }

        &:last-of-type {
          flex-basis: 100%;
          order: 3;
        }
      }
    }
  }
}

.map-section {
  // background: url("../images/map-background.jpg");
  background-size: cover !important;
  background-position: center center;
  padding: 50px 25px;
  .buttons {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .map-section {
    padding: 100px 50px;
  }
}

//INSTAGRAM

.instagram-section {
  padding: 50px 25px;
  .photos {
    margin-top: 25px;
    a {
      margin-bottom: 10px;
      display: block;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    img {
      display: none;
      width: 100%;
      align-self: center;
      &:first-of-type {
        display: block;
      }
    }
  }
}

.video-thumb-slice {
  .content {
    p {
      img.logo-text {
        display: inline;
        height: 20px;
        width: auto;
      }
    }
  }
}

.black_back {
  background: black;
  color: white;
  .button {
    border: 2px solid white;
  }
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
    // h2 {
    //   float: left;
    // }
    .flex-wrapper {
      display: flex;
      align-content: space-between;
      .content {
        // float: left;
        width: 100%;
        margin-right: 20px;
      }
      .button {
        // float: right;
        margin-bottom: 25px;
      }
    }

    .photos {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      clear: both;
      a {
        display: block;
        width: calc(100% / 3 - 20px);
        margin-right: 30px;
        // flex-basis: 0;
        &:last-of-type {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      img {
        display: block;
        width: calc(100% / 3 - 20px);
        margin-right: 30px;
        // flex-basis: 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

//PRODUCTS PAGE

.products-page-section {
  min-height: 400px;
  .wrapper {
    .carousel-container {
      padding: 25px;
      .options {
        span {
          display: inline-block;
          padding: 10px;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          border-radius: 16px;
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          margin-right: 10px;
          margin-bottom: 10px;
          border: 2px solid transparent;
          cursor: pointer;
          transition: all 300ms ease-in-out;
          &:last-of-type {
            margin-right: 0;
          }
          &.active {
            // box-shadow: none;
            background: black;
            color: white;
          }
          &:hover {
            background: $gray;
            color: white;
          }
        }
      }
      .form-info-container {
        .selected-form {
          width: 60%;
          height: auto;
          margin: 25px auto;
        }
        .text-content {
          p:last-of-type {
            margin-top: 15px;
          }
        }
      }
    }
    .product-carousel {
      position: relative;
      .images {
        width: 100%;
        height: 50vh;
        .product-image {
          width: 100%;
          height: 100%;
          display: none;
          img {
            object-fit: cover !important;
            object-position: center center !important;
          }
          &.active {
            display: block;
          }
        }
      }
      .controls {
        position: absolute;
        bottom: 25px;
        right: 25px;
        z-index: 2;
        div {
          background: white;
          border-radius: 100%;
          float: left;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          transition: all 300ms ease-in-out;
          cursor: pointer;
          &:hover {
            background: $gray;
            color: white;
          }
        }
        .forward {
          &:before {
            content: ">";
          }
        }
        .back {
          margin-right: 20px;
          &:before {
            content: "<";
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .products-page-section {
    .wrapper {
      .carousel-container {
        padding: 25px 50px;
        .options {
          text-align: center;
        }
        .form-info-container {
          display: flex;
          flex-direction: row;
          .selected-form {
            margin-right: 25px;
          }
          .text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .product-carousel {
        min-width: 60%;
        margin: 0;
        .images {
          height: 100%;
        }
      }
      .carousel-container {
        padding: 0;
        padding-right: 25px;
        min-width: 25%;
        .options {
          text-align: left;
        }
        .form-info-container {
          display: block;
          .selected-form {
            margin: 25px auto;
            width: 100%;
          }
          .text-content {
            padding: 0;
          }
        }
      }
    }
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      h2 {
        margin-right: 10%;
      }
    }
  }
}

//PRISMIC PAGES

.simple-page {
  padding: 25px;

  p,
  li,
  a {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: black;
    letter-spacing: normal;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
  }
}

.no-flick {
  min-height: 80vh;
}

.animation {
  opacity: 0;
  &.yas {
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    opacity: 1;
  }
}

.pdf-section {
  .react-pdf__Document {
    pointer-events: none;
    -webkit-user-select: none;
    overflow: hidden;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .pdf-navigation {
    text-align: center;
    z-index: 20;
    position: relative;
    .page-indicator {
      margin-bottom: 10px;
    }
    .button:first-of-type {
      margin-right: 10px;
    }
    .button {
      z-index: 20;
      color: white;
      &:hover {
        color: black;
      }
    }
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
  .interactive-map {
    width: 100%;
    display: block;
    position: relative !important;
    display: none;
  }
  .locations-list-wrapper {
    display: block;
    height: calc(100vh - 73px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .locations-list-title {
      margin: 0;
      margin-top: 10px;
      padding: 0px 20px;
      color: #000000;
    }
    .pagination-nav {
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;
      background: $gray;
      color: white;
      .page-controls {
        span {
          cursor: pointer;
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .info-message {
      background: #000000;
      color: white;
      padding: 8px 20px;
    }
    .locations-search-form {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .locations-list {
      background: white;
      overflow: scroll;
      height: 100%;
      position: relative;
      .single-location {
        padding: 20px;
        overflow: auto;
        border-bottom: 1px solid #000000;
        display: flex;
        flex-direction: row;
        &:last-of-type {
          border-bottom: 0;
        }
        &.active {
          background: rgba(0, 0, 0, 0.2);
        }

        .store-information {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #000000;
          width: 100%;
          color: #000000;
          .store-name {
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 5px;
            color: #000000;
          }
          .store-address {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 10px;
          }
          .distance {
            font-size: 14px;
            line-height: 1.2;
          }
          .usage-type {
            font-size: 12px;
            font-weight: 900;
            margin-bottom: 0;
          }
        }
        .store-actions {
          min-width: 110px;
          .icon-button {
            color: #000000;
            font-size: 10px;
            display: block;
            text-decoration: none;
            font-weight: 900;
            margin-bottom: 10px;
            white-space: nowrap;
            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              fill: #000000;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .map-wrapper {
    overflow: auto;
    .interactive-map {
      height: calc(100vh - 73px) !important;
      width: 60% !important;
      float: right;
      display: block;
    }
    .stores-wrapper {
      height: calc(100vh - 73px) !important;
      width: 40%;
      float: left;
    }
  }
}

.gm-style {
  font: inherit !important;
  .gm-style-iw-c {
    border-radius: 0 !important;
  }
}

.info-window-content {
  .store-name {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;
    color: #000000;
  }
  .store-address {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .usage-type {
    font-size: 16px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}

#alert-dialog-description {
  p {
    a {
      color: black;
      text-decoration: underline;
    }
  }
}

//slices

.full-width-slice {
  .wrapper {
    // max-width: 600px;
    .text-content {
      max-width: 600px;
    }
  }
  &.align-left {
    .wrapper {
      // margin-left: 0;
    }
  }
  &.align-right {
    .wrapper {
      .text-content {
        margin-left: auto;
      }
      // margin-right: 0;
    }
  }
}

.video-single {
  margin-top: 60px;
  .video-and-description {
    .video-embed-container {
      iframe {
        width: 100%;
        height: 500px;
      }
    }
    .video-description {
      padding: 20px;
    }
  }
  .related-videos {
    padding: 20px;
    background: black;
    h2 {
      color: white;
    }
    .related-videos-container {
      overflow: scroll;
      display: flex;
      flex-direction: row;
      a {
        text-decoration: none;
        color: white;
        margin-right: 20px;
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          object-position: center center;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .video-single {
    display: flex;
    flex-direction: row;

    .related-videos {
      box-sizing: border-box;
      overflow: scroll;
      .related-videos-container {
        display: block;
        overflow: hidden;
        a {
          margin-right: 0;
          &:last-of-type {
            margin-bottom: 0;
            h3 {
              margin-bottom: 0;
            }
          }
          img {
            width: 100%;
            height: 150px;
          }
        }
      }
    }
  }
}

.video-list-slice {
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    @media only screen and (max-width: 350px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
    max-width: 1400px;
    grid-gap: 1rem;
    margin: auto;

    .video-list-item {
      margin: 0 auto;
      transition: 300ms ease;
      &:hover {
        opacity: 0.8;
      }
      .image-content {
        position: relative;
        cursor: pointer;
        &:before {
          content: "";
          position: absolute;
          background: url(../images/icons/play-arrow.svg) no-repeat;
          height: 30px;
          width: 30px;
          z-index: 20;
          top: 47%;
          left: 47%;
        }
      }
      img {
        width: 90%;
        margin: auto;
        max-width: 500px;
        object-fit: cover;
        object-position: center top;
      }
      .text-content {
        padding: 20px 0px;
        img {
          width: 70%;
          height: auto;
          margin: 0 auto 20px;
        }
        h2 {
          font-weight: normal;
          text-align: center;
          font-size: 1rem;
          min-height: 36px;
        }
        .button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.filters {
  text-align: center;
  margin-bottom: 2rem;

  .sort {
    text-align: center;
  }
  .filter-copy p:first-child {
    margin-bottom: 0;
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .filters {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .filters-wrapper {
      display: flex;
      justify-content: center;
    }

    .filter-copy {
      max-width: 600px;
      margin: auto;
    }
    .sort {
      align-self: center;
      margin-right: 2rem;
      font-size: 1.5rem;
    }
  }
}

.playVideo {
  margin: 0;
  padding: 0;
  color: black;
}

@media only screen and (min-width: 960px) {
  .video-list-slice {
    .video-list-item {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      img {
        height: 100%;
        max-width: none;
      }
      .text-content {
        padding: 20px;
      }
    }
  }
  .iframe-slice {
    width: 100%;
    iframe {
      width: 100%;
      height: 50vh;
      border: 0;
    }
    margin-bottom: 100px;
  }
}

.faq-section {
  .question {
    cursor: pointer;
  }
  table {
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 !important;
    }
    div {
      margin: 0;
    }
    .answer {
      padding: 20px;
    }
  }
}

.standard-padding {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 100px 50px;
  }
}

.button-container {
  margin-top: 35px;
  a {
    button {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    &:last-of-type() {
      button {
        margin-bottom: 0px;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .button-container {
    margin-top: 35px;
    a {
      button {
        margin-left: 10px;
        margin-bottom: 0;
        display: inline-block;
      }
      &:nth-of-type(1) {
        button {
          margin-left: 0px;
        }
      }
    }
  }
}

#contact-page {
  .wrapper {
    max-width: 700px;
    padding: 20px;
  }
}

#contact-header {
  background: black;
  h1 {
    text-align: center;
    padding: 40px 0px;
    color: white;
    margin: 0;
  }
}

.form-message {
  display: block;
  background: black;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  p {
    color: inherit;
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: inherit;
  }
  &.error {
    color: $errorRed;
    background: none;
    border: 1px solid $errorRed;
  }
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.flex {
  display: flex;
}

// INFO BOX SLICE
.info-box-slice {
  text-align: center;
  .wrapper {
    margin: auto;
    padding: 40px;
    h2 {
      margin-bottom: 40px;
    }
  }

  .info-boxes {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-gap: 16px;
    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(146px, 239px));
    }
  }

  .box-wrapper {
    margin-bottom: 40px;
    box-sizing: border-box;
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
    p,
    ul {
      font-size: 14px;
      max-width: 280px;
      margin: auto;
    }
    ul {
      margin-top: 20px;
      text-align: left;
      display: inline-block;
      li {
        margin-bottom: 20px;
      }
    }
    .box {
      box-sizing: border-box;
      background: black;
      color: white;
      width: 100%;
      width: 146px;
      height: 131px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 28px 12px;
      margin: 0 auto 20px;
    }
  }

  @media only screen and (min-width: 600px) {
    .wrapper .content {
      margin-bottom: 30px;
    }
    .box-wrapper {
      h2 {
        font-size: 22px;
      }
      .box {
        height: 214px;
        width: 100%;
      }
    }
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid white;
}

.info-wrapper {
  background-color: black;
  color: white;

  &.not-expanded {
    padding: 15px;
  }
  &.expanded {
    padding: 30px;
  }

  .toggle-button {
    color: white;
    padding: 0;
    margin: 0;

    h2 {
      margin-bottom: 10px;
    }
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    .triangle-down {
      margin: auto;
    }
  }
  .collapse-wrapper {
    display: flex;
    justify-content: center;
    button {
      color: white;
      .triangle-up {
        margin: auto;
        margin-bottom: 10px;
      }
    }
  }
  .toggled-info {
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .collapse,
  .toggle-button {
    cursor: pointer;
  }
}

@media only screen and (min-width: 800px) {
  .info-drop-down-section {
    display: flex;
    margin: 60px auto;
  }
  .info-wrapper {
    display: inline-block;
    max-width: 800px;
    margin: auto;
    &.not-expanded {
      padding: 15px 30px;
    }
    &.expanded {
      padding: 60px;
      p,
      ol,
      ul {
        font-size: 14px;
      }
      h3,
      h4 {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }

    .toggle-button {
      display: flex;

      h2 {
        font-size: 22px;
      }
      .triangle-down {
        margin-bottom: 15px;
        margin-left: 15px;
      }
    }
  }
}

#budtender.iframe-slice {
  padding: 0 40px;
  margin: 20px auto;
  box-sizing: border-box;

  .copy-wrapper {
    text-align: center;
    margin: 40px auto 20px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      height: 900px;
      width: 100%;
      max-width: 700px;
    }
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}
